import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/Mondaic/infrastructure/website/src/templates/mdx_template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Services`}</h1>
    <p>{`In addition to licenses for our core Salvus product we offer a number of
services around it.`}</p>
    <h2>{`On-Site Training/Workshop`}</h2>
    <p>{`A 3 to 5 day Salvus workshop at your venue with two instructors. Typically,
we cover full waveform modeling and inversion from theory to applications,
involving comprehensive hands-on sessions with the Salvus software suite. The
workshop schedule is flexible and the contents can be adapted to your
specific needs. The required computations can either be done using
cloud-based services provided by Mondaic or on your local hardware. The main
incentives of the workshop are to provide insights into the underlying
physics and numerical methods, to accelerate the learning curve, and to give
users a head start to use Salvus for their own work.`}</p>
    <h2>{`24h Priority Technical Support`}</h2>
    <p>{`Technical priority support and trouble shooting. We guarantee an initial
response time of less than 24 hours during work days.`}</p>
    <h2>{`Application Support and Services`}</h2>
    <p>{`Expert consulting and specialized assistance in the wider field of waveform
modeling and inversion. This includes but is not limited to`}</p>
    <ul>
      <li parentName="ul">{`modeling`}</li>
      <li parentName="ul">{`meshing`}</li>
      <li parentName="ul">{`scripting guidance`}</li>
      <li parentName="ul">{`workflow design`}</li>
      <li parentName="ul">{`visualizations`}</li>
      <li parentName="ul">{`quality control and`}</li>
      <li parentName="ul">{`process management`}</li>
    </ul>
    <h2>{`Custom Development`}</h2>
    <p>{`Implementation of custom features and add-ons on request, for example
tailored pre-and post-processing modules, data converters, specialized
modeling and workflows, etc.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      